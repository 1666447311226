.lazyloaderLayout {
  & .cardBox {
    display: flex;
    gap: 20px;
    width: 100%;

    & .cardColumn {
      width: 200px;
      /* height: 200px; */
      background: linear-gradient(-45deg, #f7f8fa, #d8d8d8);
      background-size: 400% 400%;
      -webkit-animation: gradient 2s ease infinite;
      animation: gradient 2s ease infinite;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      padding: 18px 35px;
      flex: 1;

      & .cardIcon {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        background: #e1e1e1;
      }

      & .cardTitle {
        width: 100%;
        height: 20px;
        border-radius: 5px;
        background: #e1e1e1;
      }
    }
  }

  & .bottomLayout {
    display: flex;
    gap: 20px;
    width: 100%;
    margin-top: 20px;

    & .smallCard {
      width: 200px;
      height: 246px;
      border-radius: 3px;
      background: linear-gradient(-45deg, #f7f8fa, #d8d8d8);
      background-size: 400% 400%;
      -webkit-animation: gradient 2s ease infinite;
      animation: gradient 2s ease infinite;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      padding: 0 25px;
      flex: 1;

      & .straightLine {
        height: 10px;
        background: #e1e1e1;
        width: 100%;
      }
    }

    & .bigCard {
      flex: 6;
      width: 100%;
      padding: 28px 38px;
      height: 246px;
      border-radius: 3px;
      background: linear-gradient(-45deg, #f7f8fa, #d8d8d8);
      background-size: 400% 400%;
      -webkit-animation: gradient 2s ease infinite;
      animation: gradient 2s ease infinite;
      & .bigcardHeading{
        height: 20px;
        background: #e1e1e1;
        width: 35%;
      }
      & .bigcardSubHeading{
        margin-top: 24px;
        height: 20px;
        background: #e1e1e1;
        width: 60%;
      }
      & .bigcardDescription{
        margin-top: 14px;
        height: 50px;
        background: #e1e1e1;
        width: 100%;
      }
      & .bigcardLink{
        margin-top: 6px;
        height: 20px;
        background: #e1e1e1;
        width: 30%;
      }
    }
  }
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@media(min-width:1025px){
  .cardBoxMargin{
    margin: auto 98px;
    width: auto!important;
  }
}