@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: auto;
  max-height: 600px;
  padding: 0;

  @media (--viewportMedium) {
    min-height: 500px;
    height: auto;
    max-height: none;
  }

  @media (--viewportLarge) {
    min-height: 600px;
  }
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 145px;
    padding-top: 134px;
  }
}

.heroHeader {
  padding: 0 24px;

  & h1 {
    font-size: 32px !important;
  }

  & h2 {
    font-size: 16px !important;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: hidden;

  @media (--viewportMedium) {
    /*overflow: auto;*/
    overflow-x: hidden;
  }
}

/* Square corners for the last section if it's even */

/* Every other section has a light background */
.section:nth-of-type(2) {
  background-color: var(--matterColorLightGray);

  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 60px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 80px 24px;
  }

  @media (--viewportLarge) {
    max-width: calc(1300px + 36px + 36px);
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 53px auto;
  }

  @media (--viewportXLarge) {
    max-width: calc(1300px + 36px + 36px);
    padding: 0;
  }
}

.sectionWorks {
  margin: 40px 24px 25px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: 40px 24px 40px 24px;
  }

  @media (--viewportLarge) {
    max-width: calc(1300px + 30px + 30px);
    padding: 0 36px 0 36px;
    margin: 40px auto 40px auto;
  }

  @media (--viewportXLarge) {
    max-width: calc(1300px + 30px + 30px);
    padding: 0;
  }
}

.sectionContentFirstChild {
  margin-top: 22vh;

  @media (--viewportMedium) {
    composes: sectionContent;
    margin-top: 3vh;
  }

  @media(max-width: 767px) {
    margin-top: 22vh;
  }

  /* @media(max-width: 460px){
    margin-top: 33vh;
  } */
}

.nonApprovedUserInfo {
  position: fixed;
  width: 100%;
  background: var(--marketplaceColor);
  margin: 0;
  padding: 24px;
  z-index: 3;
  box-shadow: var(--boxShadow);
  text-align: center;
}

.nonApprovedUserInfoLink {
  color: var(--matterColorLight);
}

.termsPara {
  color: red;
}

.termsBox {
  overflow-y: auto;
  height: 60vh;
  margin-bottom: 15px;
  padding-right: 25px;
}

.verifyEmailMessageButton {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH5FontStyles;
  /* padding: 1rem;
  background-color: #2FA4F8;
  color: white;
  text-decoration: none;
  width: fit-content; */

}

.loaderWrapper {
  /* display: flex;
  justify-content: center; */
  margin-top: 2em;
}

.mainWrapper {
  width: 1024px;
  margin: 48px auto;
  padding: 0 20px;

  @media(max-width: 1024px) {
    width: 100%;
  }

  & .mainTitles {
    & .welcomeTitle {
      margin-top: 0;
      font-size: 40px;
      font-weight: 500;
      color: #3d3d3d;
      line-height: 60px;
      margin-bottom: 1dvh;
      letter-spacing: -1.25px;

      @media(max-width: 1024px) {
        text-align: center;
      }
    }

    & .welcomeSubTitle {
      margin: 0;
      font-size: 20px;
      font-weight: 400;
      color: #3d3d3d;
      line-height: 30px;
      letter-spacing: -0.08888888359069824px;

      @media(max-width: 1024px) {
        text-align: center;
      }
    }
  }
}